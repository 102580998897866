define("discourse/plugins/discourse-mlm-daily-summary/discourse/initializers/mlm-daily-summary", ["exports", "discourse-common/utils/decorators", "discourse/controllers/preferences/emails", "discourse/controllers/user"], function (_exports, _decorators, _emails, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'mlm_daily_summary',
    initialize(container) {
      var _dec, _obj;
      _emails.default.reopen((_dec = (0, _decorators.observes)("model.custom_fields.user_mlm_daily_summary_enabled"), (_obj = {
        userMLMDailySummaryEnabled() {
          const user = this.get("model");
          return user.get("custom_fields.user_mlm_daily_summary_enabled");
        },
        _setUserMLMDailySummary() {
          var attrNames = this.get("saveAttrNames");
          attrNames.push('custom_fields');
          this.set("saveAttrNames", attrNames);
          const user = this.get("model");
          const userMLMDailySummaryEnabled = user.custom_fields.user_mlm_daily_summary_enabled;
          user.set("custom_fields.user_mlm_daily_summary_enabled", userMLMDailySummaryEnabled);
        }
      }, (_applyDecoratedDescriptor(_obj, "_setUserMLMDailySummary", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setUserMLMDailySummary"), _obj)), _obj)));
    }
  };
});